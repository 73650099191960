import { useRouter } from "next/router";
import React from "react";

import { classes } from "../../helpers";
import { LinkToIndex, LinkToLatest, LinkToSubscriptions } from "../Link";
import { SearchForm } from "../SearchForm";

export function TopNav() {
  const router = useRouter();

  const classNameBasedOnRoute = (route: string) => {
    const isActive = router.pathname == route;

    return classes("topnav-item", isActive && "topnav-item--active");
  };

  return (
    <>
      <div className="topnav topnav--main">
        <LinkToIndex className={classNameBasedOnRoute("/")}>
          Toppnyheter
        </LinkToIndex>
        <LinkToLatest className={classNameBasedOnRoute("/latest")}>
          Senaste nytt
        </LinkToLatest>
        <LinkToSubscriptions
          className={classNameBasedOnRoute("/subscriptions")}
        >
          Bevakningar
        </LinkToSubscriptions>
      </div>
      <div className="topnav topnav--search hidden-xs hidden-sm">
        <SearchForm />
      </div>
    </>
  );
}
