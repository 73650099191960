import React from "react";

import { getConfig } from "../../../../../config";

export function PremiumLabel() {
  const { NEXT_PUBLIC_NEWSROOM, NEXT_PUBLIC_MER } = getConfig();

  const isOmniNewsroom = NEXT_PUBLIC_NEWSROOM === "omni";
  if (isOmniNewsroom && !NEXT_PUBLIC_MER) return null;

  const label = isOmniNewsroom ? "Omni Mer" : "Medlem";
  return <div className="btn btn--primary btn--premium">{label}</div>;
}
