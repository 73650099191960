import React from "react";

import { PremiumLabel } from "./PremiumLabel";
import { useGlobalStore } from "../../../../../containers/GlobalStateContainer/store";

export function LoggedMenuProfile() {
  const {
    userInfo: { email, name },
    subscriptionsInfo: { isPremium }
  } = useGlobalStore();

  return (
    <div className="user-profile--section">
      <div className="user-profile--name">
        <span> Hej {name || email}!</span>
        {isPremium && <PremiumLabel />}
      </div>
    </div>
  );
}
