import React, { useCallback } from "react";

import type { Category } from "../../../../../client";

import { Toggler } from "./Toggler";
import { Slider } from "./Slider";
import { updateSettingsField } from "../../../../../client";
import { showMessage } from "../../../../../helpers";
import { useGlobalStore } from "../../../../../containers/GlobalStateContainer/store";

const options = [
  {
    value: 0.0,
    text: "Inget"
  },
  {
    value: 0.33,
    text: "Endast större"
  },
  {
    value: 0.66,
    text: "Normalt"
  },
  {
    value: 1.0,
    text: "Allt"
  }
];

export function UsermixMenu() {
  const {
    personalizedContent: { usermix },
    setPersonalizedContent,
    categories
  } = useGlobalStore();

  const getUsermixValue = useCallback(
    ({ category_id }: Category) => {
      if (!usermix || !category_id) {
        return 0;
      }
      return usermix[category_id];
    },
    [usermix]
  );

  const setUsermixValue = useCallback(
    async ({ category_id }: Category, value: number) => {
      const newUsermix = {
        ...usermix,
        [category_id]: value
      };

      setPersonalizedContent({ usermix });

      try {
        await updateSettingsField("usermix", { value: newUsermix });
      } catch (e) {
        console.error(e);
      }
      showMessage({
        text: "Din nyhetsmix har sparats!",
        hideAfter: 1000,
        id: "SET_USERMIX_CONFIRMATION"
      });
    },
    [setPersonalizedContent, usermix]
  );

  return (
    <div className="menu menu-mega">
      <section className="menu-mega-usermix">
        <h1 className="menu-mega-heading">Ändra din nyhetsmix</h1>
        <p className="menu-mega-infotext">
          Ställ in vilka nyheter du vill se i Toppnyheter och Senaste nytt.
        </p>
        <div className="usermix-captions">
          {options.map(({ text }) => (
            <div key={text} className="usermix-caption">
              {text}
            </div>
          ))}
        </div>
        <div className="usermix-categories">
          {categories
            .filter(category => !category.feature_category)
            .map(category => (
              <Slider
                setUsermixValue={setUsermixValue}
                getUsermixValue={getUsermixValue}
                category={category}
                key={category.category_id}
              />
            ))}
        </div>

        <div className="u-thickhr usermix-divider" />
        {categories
          .filter(category => category.feature_category)
          .map(category => (
            <Toggler
              setUsermixValue={setUsermixValue}
              getUsermixValue={getUsermixValue}
              category={category}
              key={category.category_id}
              values={[options[0].value, options[3].value]}
            />
          ))}
      </section>
    </div>
  );
}
