import React, { useCallback, useContext } from "react";

import type { ReactNode } from "react";
import type { Article } from "../../client/ContentApi/models";

import { TrackingContext } from "../../contexts/TrackingContext";
import { showMessage } from "../../helpers";
import { postBookmark } from "../../client/SettingsApi/methods/postBookmark";
import { deleteBookmark } from "../../client/SettingsApi/methods/deleteBookmark";
import { useGlobalStore } from "../../containers/GlobalStateContainer/store";

export function AddBookmarkLink({
  article,
  children,
  className
}: {
  article: Article;
  children: ReactNode;
  className: string;
}) {
  const {
    isLoggedIn,
    personalizedContent: { bookmarks },
    setPersonalizedContent
  } = useGlobalStore();
  const { bookmarkAdded } = useContext(TrackingContext);

  const addBookmark = useCallback(
    async (article: Article) => {
      if (!isLoggedIn) {
        showMessage({
          text: "Du måste logga in för att bevaka ämnen",
          id: "logInToBookmark",
          hideAfter: 1000
        });
        return;
      }

      try {
        await postBookmark(article.article_id);

        const bookmark = {
          article_id: article.article_id,
          created_at: new Date().toISOString()
        };

        setPersonalizedContent({ bookmarks: [...bookmarks, bookmark] });

        showMessage({
          id: `bookmarked${article.article_id}`,
          text: `Artikeln har sparats!`,
          hideAfter: 1500
        });
      } catch (error) {
        console.error(error);
      }
    },
    [bookmarks, isLoggedIn, setPersonalizedContent]
  );

  return (
    <button
      className={className}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        addBookmark(article);
        bookmarkAdded(article);
      }}
    >
      {children}
    </button>
  );
}

export function RemoveBookmarkLink({
  article,
  children,
  className
}: {
  article: Article;
  children: ReactNode;
  className: string;
}) {
  const {
    isLoggedIn,
    personalizedContent: { bookmarks = [] } = {},
    setPersonalizedContent
  } = useGlobalStore();

  const removeBookmark = useCallback(
    async (article: Article) => {
      if (!isLoggedIn) {
        return;
      }

      try {
        await deleteBookmark(article.article_id);

        setPersonalizedContent({
          bookmarks: [
            ...bookmarks.filter(
              bookmark => bookmark.article_id !== article.article_id
            )
          ]
        });

        showMessage({
          id: `bookmarked${article.article_id}`,
          text: `Artikeln har tagits bort från sparade artiklar.`,
          hideAfter: 1500
        });
      } catch (error) {
        console.error(error);
      }
    },
    [bookmarks, isLoggedIn, setPersonalizedContent]
  );

  return (
    <button
      className={className}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        removeBookmark(article);
      }}
    >
      {children}
    </button>
  );
}
