import React, { useContext } from "react";

import { LoggedNav } from "./LoggedNav";
import { NotLoggedNav } from "./NotLoggedNav";
import { LayoutContext } from "../../../contexts/LayoutContext";
import { Menu } from "./Menu";
import { useMenu } from "../../../hooks/useMenu";
import { useGlobalStore } from "../../../containers/GlobalStateContainer/store";

export function RightNav() {
  const { isLoggedIn } = useGlobalStore();
  const { headerRightNav } = useContext(LayoutContext);

  const { currentPanel, openPanel, closePanel } = useMenu();

  const isOpen = !!currentPanel;

  const onBlur = (event: React.FocusEvent) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      closePanel();
    }
  };

  return (
    <div
      className={`topnav topnav--right ${
        isLoggedIn ? "topnav--right--loggedin" : ""
      }`}
      onBlur={onBlur}
      tabIndex={-1}
    >
      {isLoggedIn ? (
        <LoggedNav
          isOpen={isOpen}
          currentPanel={currentPanel}
          openPanel={openPanel}
        />
      ) : headerRightNav ? (
        headerRightNav()
      ) : (
        <NotLoggedNav />
      )}
      {isOpen && <Menu currentPanel={currentPanel} />}
    </div>
  );
}
