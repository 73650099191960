import React from "react";

import BookmarkIcon from "../../../../../assets/svg/bookmark-icon.svg";
import EnvelopeIcon from "../../../../../assets/svg/envelope.svg";
import SubscribeIcon from "../../../../../assets/svg/subscribe-icon.svg";
import { FeatureEnum, hasFeature } from "../../../../../config/features";
import { LinkToBookmarks, LinkToSubscriptions } from "../../../../Link";
import { getConfig } from "../../../../../config";

export const LoggedMenuSettings = () => {
  const { NEXT_PUBLIC_NEWSROOM } = getConfig();
  const isOmnieko = NEXT_PUBLIC_NEWSROOM === "omnieko";

  return (
    <div className="menu-mega-settings menu-mega--middle">
      <div className="menu-mega-settings-links">
        {hasFeature(FeatureEnum.BOOKMARKS) && (
          <div className="menu-mega-settings-links--container">
            <div className="menu-mega-settings-links--icon">
              <BookmarkIcon />
            </div>
            <LinkToBookmarks className="menu-mega-settings-links-link">
              Sparade artiklar
            </LinkToBookmarks>
          </div>
        )}
        <div className="menu-mega-settings-links--container">
          <div className="menu-mega-settings-links--icon">
            <SubscribeIcon />
          </div>
          <LinkToSubscriptions className="menu-mega-settings-links-link">
            Bevakningar
          </LinkToSubscriptions>
        </div>
        {isOmnieko && (
          <div className="menu-mega-settings-links--container">
            <div className="menu-mega-settings-links--icon">
              <EnvelopeIcon />
            </div>
            <a
              rel="noopener noreferrer"
              href="https://omni.se/minsida/nyhetsbrev"
              target="_blank"
              className="menu-mega-settings-links-link"
            >
              Nyhetsbrev
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
