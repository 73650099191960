import React from "react";

import { LoggedMenuMembership } from "./LoggedMenuMembership";
import { LoggedMenuSettings } from "./LoggedMenuSettings";
import { LoggedMenuProfile } from "./LoggedMenuProfile";

export const ProfileMenu = () => (
  <div className="menu menu-mega">
    <LoggedMenuProfile />
    <section className="menu-mega-user">
      <LoggedMenuSettings />
      <div className="menu-mega-divider" />
      <LoggedMenuMembership />
      <div className="menu-mega-divider" />

      <div className="menu-mega--underline menu-mega--bottom">
        Inloggad med konto hos Schibsted.
        <a
          href="https://info.privacy.schibsted.com/se/vad-ar-ett-schibsted-konto/"
          target="_blank"
          rel="noreferrer"
        >
          Läs mer
        </a>
      </div>
    </section>
  </div>
);
