import React from "react";

import type { Category } from "../../../../../client";

import { LinkToCategory } from "../../../../Link";
import { Toggle } from "./Toggle";

export function Toggler({
  category,
  values,
  getUsermixValue,
  setUsermixValue
}: {
  values: number[];
  category: Category;
  getUsermixValue: (category: Category) => number;
  setUsermixValue: (category: Category, value: number) => void;
}) {
  return (
    <div className="usermix-category usermix-category--toggle">
      <LinkToCategory
        category={category}
        className="usermix-category-title usermix-category-title--toggle"
      >
        {category.title}
      </LinkToCategory>
      <div className="usermix-category-options usermix-category-options--toggle">
        {values.map(value => (
          <Toggle
            setUsermixValue={setUsermixValue}
            getUsermixValue={getUsermixValue}
            key={value}
            category={category}
            value={value}
          />
        ))}
        <div
          className={`usermix-category-options-line usermix-category-options-line--toggle usermix-category-options-line--${category.slug}`}
        />
      </div>
    </div>
  );
}
