/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-noninteractive-tabindex */

import React from "react";

import type { Category } from "../../../../../client";

const tabAndEnterKeyCodes = [32, 13];

export function Toggle({
  category,
  value,
  getUsermixValue,
  setUsermixValue
}: {
  category: Category;
  value: number;
  getUsermixValue: (category: Category) => number;
  setUsermixValue: (category: Category, value: number) => void;
}) {
  const checkedValue = getUsermixValue(category);

  const stateLabel = value ? "Visas inte i flödet" : "Visas i flödet";
  const iconState = value ? "" : "icon--check--active";
  return (
    <label
      tabIndex={0}
      className="usermix-category-option"
      onKeyUp={e =>
        tabAndEnterKeyCodes.includes(e.keyCode) &&
        setUsermixValue(category, value)
      }
    >
      <input
        type="radio"
        className="usermix-category-option-radio"
        name={category.category_id}
        value={value}
        onChange={e => setUsermixValue(category, value)}
        checked={checkedValue === value}
      />
      <span
        className={`usermix-category-option-label
      usermix-category-option-label--${category.category_id}
      usermix-category-option-label--toggle`}
      >
        <i className={`icon icon--check ${iconState}`} />
      </span>
      <span className="usermix-category-option-state">{stateLabel}</span>
    </label>
  );
}
