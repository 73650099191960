import UserMixSlider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import React, { useContext } from "react";

import type { Category } from "../../../../../client";

import { PremiumLockContext } from "../../../../../containers/PremiumLockContainer";
import { LinkToCategory } from "../../../../Link";
import { MenuIcon } from "../../../LeftNav/Menu/MenuIcon";

const maybeInteger = (value: number | number[]) =>
  (value === 0.99 ? 1.0 : value) as number;

export function Slider({
  category,
  getUsermixValue,
  setUsermixValue
}: {
  category: Category;
  getUsermixValue: (category: Category) => number;
  setUsermixValue: (category: Category, value: number) => void;
}) {
  const { checkLock } = useContext(PremiumLockContext);

  if (category.type !== "Category" || category.usermix_display === false)
    return null;

  const marks = [
    {
      value: 0,
      label: ""
    },
    {
      value: 0.33,
      label: ""
    },
    {
      value: 0.66,
      label: ""
    },
    {
      value: 1,
      label: ""
    }
  ];

  return (
    <div className="usermix-category">
      <LinkToCategory category={category} className="usermix-category-title">
        <MenuIcon category={category} />
        {category.title}
      </LinkToCategory>
      <div className="usermix-category-options">
        <Box sx={{ width: "90%", margin: "0 auto" }}>
          <UserMixSlider
            size="small"
            defaultValue={getUsermixValue(category)}
            onChangeCommitted={(_, value: number | number[]) => {
              if (checkLock()) {
                return;
              }
              setUsermixValue(category, maybeInteger(value));
            }}
            aria-label="Temperature"
            step={0.33}
            marks={marks}
            min={0}
            max={1}
          />
        </Box>
      </div>
    </div>
  );
}
