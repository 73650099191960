import React from "react";

import { classes } from "../../../helpers";
import { SCREEN_MIN_SIZES, useScreenSize } from "../../../hooks/useScreenSize";
import { getConfig } from "../../../config";
import { login } from "../../../containers/GlobalStateContainer/userState/actions/login";

const buttonClasses = "btn topnav--right-btn btn--medium";
const primaryClass = "btn--primary";
const secondaryClass = "btn--secondary btn--light";

export const NotLoggedNav = () => {
  const {
    NEXT_PUBLIC_PREMIUM_NEWSROOM,
    translation: { get_premium_topbar }
  } = getConfig();
  const isOmnieko = NEXT_PUBLIC_PREMIUM_NEWSROOM;
  const { width } = useScreenSize();
  const isSmOrBigger = width >= SCREEN_MIN_SIZES.sm;

  const logInButtonClass = isOmnieko ? secondaryClass : primaryClass;
  const subscriptionButtonClass = isOmnieko ? primaryClass : secondaryClass;
  return (
    <>
      <a
        href={get_premium_topbar.url}
        className={classes(
          buttonClasses,
          subscriptionButtonClass,
          "topnav-omnimer"
        )}
      >
        {(!isSmOrBigger && get_premium_topbar.title_short) ||
          get_premium_topbar.title}
      </a>

      <button
        className={classes(buttonClasses, logInButtonClass)}
        onClick={login}
      >
        Logga in
      </button>
    </>
  );
};
