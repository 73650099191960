import React from "react";

import type { Panel } from "../../../../hooks/useMenu";

import { ProfileMenu } from "./ProfilePanel";
import { UsermixMenu } from "./UsermixPanel";

const panelSelection = {
  newsmix: UsermixMenu,
  profile: ProfileMenu
};

type MenuProps = {
  currentPanel: Panel;
};

const Menu: React.FC<MenuProps> = ({ currentPanel }) => {
  const Component =
    currentPanel === "newsmix" || currentPanel === "profile"
      ? panelSelection[currentPanel]
      : null;
  return <>{Component ? <Component /> : null}</>;
};

export { Menu };
